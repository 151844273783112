import ButtonSecondary from "components/Button/ButtonSecondary"
import Image from "components/Image"
import image from "assets/images/home-page/ebook_16x9.png"
import imageFull from "assets/images/home-page/ebook_full.jpg"
import BackgroundSection from "components/BackgroundSection"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useTailwindBreakpoint from "hooks/useTailwindBreakpoint"

function Section5({ className = "", img = image }) {
  return (
    <div className="container relative py-16 my-0 md:my-20">
      <BackgroundSection />
      <Ebook className={className} img={img} />
    </div>
  )
}

function Ebook({ className = "", img = image }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    screenSize: { width },
  } = useTailwindBreakpoint()

  const desktop = () => {
    return (
      <div
        className={`nc-ContactUsSection relative flex flex-col lg:flex-row items-center  ${className}`}
      >
        <div className="flex-grow">
          <Image
            alt="hero"
            sizes="(max-width: 768px) 100vw, 50vw"
            src={img}
            className="rounded-2xl"
          />
        </div>

        <div className="flex-shrink-0 mt-14 lg:mt-0 lg:ml-36 lg:w-2/5">
          <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
            {t("Ebook_Home_Intro")}
          </span>
          <h2 className="font-semibold text-3xl sm:text-4xl mt-3">{t("Ebook_Home")}</h2>
          <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
            {t("Ebook_Home_Desc")}
          </span>
          <ButtonSecondary onClick={() => navigate("/kontakt")} className="mt-8">
            {t("Ebook_Order")}
          </ButtonSecondary>
        </div>
      </div>
    )
  }

  const mobile = () => {
    return (
      <div
        className={`nc-ContactUsSection relative flex flex-col lg:flex-row items-center  ${className}`}
      >
        <div className="flex-shrink-0 mb-14 lg:mb-0 lg:ml-36 lg:w-2/5">
          <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
            {t("Ebook_Home_Intro")}
          </span>
          <h2 className="font-semibold text-3xl sm:text-4xl mt-3">{t("Ebook_Home")}</h2>
          <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
            {t("Ebook_Home_Desc")}
          </span>
          <ButtonSecondary onClick={() => navigate("/kontakt")} className="mt-8">
            {t("Ebook_Order")}
          </ButtonSecondary>
        </div>

        <div className="flex-grow">
          <Image
            alt="hero"
            sizes="(max-width: 768px) 90vw, 50vw"
            src={imageFull}
            className="rounded-2xl"
          />
        </div>
      </div>
    )
  }

  return width === "sm" || width === "xs" ? mobile() : desktop()
}

export default Section5
