import Image from "components/Image"
import { useTranslation } from "react-i18next"

function Section3({ className = "", imgAds }) {
  const { t } = useTranslation()

  return (
    <div className="my-10 md:my-10">
      <a
        href="https://www.pogledibioskop.com/"
        target="_blank"
        rel="noreferrer"
        className={`nc-SectionAds block text-center mx-auto ${className}`}
      >
        <span className="text-xs text-neutral-500">- {t("Ad")} -</span>
        <Image className="mx-auto rounded-2xl md:w-[60%]" src={imgAds} alt="ads" />
      </a>
    </div>
  )
}

export default Section3
