import Logo from "components/Header/Logo"
import Image from "components/Image"
import {
  discord_48_Png,
  instagram_48_Png,
  tiktok_48_Png,
  youTube_48_Png,
} from "components/SocialMediaIcon/icons"
import { useTranslation } from "react-i18next"

function Footer() {
  const { t } = useTranslation()

  return (
    <div className="nc-Footer container relative py-7 md:py-4 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className=" mt-4 md:mt-0 px-4 flex flex-col gap-y-3 lg:flex-row justify-center lg:justify-between items-center">
        <div className="flex items-center mb-4 lg:mb-0">
          <div className="ttnc-logo inline-block text-primary-6000 flex-shrink-0 mr-3">
            <Logo />
          </div>
          <span className="text-md font-bold">Mladen Body Building</span>
        </div>

        <div className="flex justify-center lg:justify-around w-full lg:w-auto mb-4 lg:mb-0 md:gap-x-4">
          <a
            href={"https://www.instagram.com/chetniksesports/"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Image src={instagram_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">Instagram</span>
          </a>

          <a
            href={"https://discord.com/invite/35euMVw"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={discord_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">Discord</span>
          </a>

          <a
            href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={youTube_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">YouTube</span>
          </a>

          <a
            href={"https://www.tiktok.com/@chetniksesports"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={tiktok_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">TikTok</span>
          </a>
        </div>

        <div className="text-sm flex items-center space-x-2 mt-4 lg:mt-0">
          <div className="lg:inline">{t("DesignAndDevelopment")}</div>
          <div className="lg:inline">-</div>
          <a
            href="mailto:pavle.vlajic@outlook.com"
            className="text-secondary-6000 hover:text-secondary-500 dark:text-secondary-300 dark:hover:text-secondary-6000 text-link"
          >
            {t("PavleVlajic")}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
