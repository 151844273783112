import { axiosBase } from "api/aBase"
import poglediBioskopPng from "assets/images/banners/pogledi_bioskop.png"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Section1 from "./Section1"
import Section2 from "./Section2"
import Section3 from "./Section3"
import Section4 from "./Section4"
import Section5 from "./Section5_Ebook"

function HomePage() {
  const [articles, setArticles] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    axiosBase
      .get(`/article/articles`)
      .then(x => {
        console.log(x)
        console.log(x.data.data.items)
        setArticles(x.data.data.items)
      })
      .catch(x => {
        console.log(x)
      })
  }, [])

  return (
    <>
      <Section1 />
      {/* <div className="nc-PageHomeDemo3 relative">
        <div className="container relative">
          <SectionAbout2
            className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
            heading={
              <span>
                Far from face <br /> but not from {` `}
                <span className="relative pr-3">
                  <span className="relative">heart</span>
                </span>
              </span>
            }
            btnText="Getting started"
            subHeading="Let stay at home and share with everyone the most beautiful stories in your hometown 🎈"
          />
        </div>
      </div> */}
      <Section2 articles={articles} />
      {/* <Section3 imgAds={poglediBioskopPng} /> */}
      <Section4 />
      <Section5 />
    </>
  )
}

export default HomePage
