import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import ButtonClose from "components/Button/ButtonClose"
import Logo from "components/Header/Logo"
import Image from "components/Image"
import Link from "components/Link"
import {
  discord_48_Png,
  instagram_48_Png,
  tiktok_48_Png,
  youTube_48_Png,
} from "components/SocialMediaIcon/icons"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const randomId = _.uniqueId

function NavMobile({ onClickClose }) {
  const { t } = useTranslation()

  const _renderMenuChild = (
    item,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={index} as="li">
            <Link
              href={{
                pathname: i.href || undefined,
              }}
              className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
            >
              <span
                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span className="flex items-center flex-grow" onClick={e => e.preventDefault()}>
                  <Disclosure.Button as="span" className="flex justify-end flex-grow">
                    <ChevronDownIcon className="ml-2 h-4 w-4 text-slate-500" aria-hidden="true" />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(i, "pl-3 text-slate-600 dark:text-slate-400 ")}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    )
  }

  const _renderItem = (item, index) => {
    return (
      <Disclosure key={index} as="li" className="text-slate-900 dark:text-white">
        <Link
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          href={{
            pathname: item.href || undefined,
          }}
        >
          <span className={!item.children ? "block w-full" : ""} onClick={onClickClose}>
            {item.name}
          </span>
          {item.children && (
            <span className="block flex-grow" onClick={e => e.preventDefault()}>
              <Disclosure.Button as="span" className="flex justify-end flex-grow">
                <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
      </Disclosure>
    )
  }

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
          <span>{t("Home_About1")}</span>

          <div className="flex justify-between items-center mt-4">
            {/* <SocialsListHeader itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" /> */}

            <a href={"https://www.instagram.com/chetniksesports/"} target="_blank" rel="noreferrer">
              <Image src={instagram_48_Png} />
            </a>

            <a href={"https://discord.com/invite/35euMVw"} target="_blank" rel="noreferrer">
              <Image src={discord_48_Png} />
            </a>

            <a
              href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={youTube_48_Png} />
            </a>

            <a href={"https://www.tiktok.com/@chetniksesports"} target="_blank" rel="noreferrer">
              <Image src={tiktok_48_Png} />
            </a>

            <span className="block">
              {/* <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" /> */}
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {[
          {
            id: randomId(),
            href: "/",
            name: t("Page_Home"),
          },

          {
            id: randomId(),
            href: "/galerija",
            name: t("Page_History"),
          },

          {
            id: randomId(),
            href: "/kontakt",
            name: t("Page_ContactUs"),
          },
        ].map(_renderItem)}
      </ul>
      {/* <LanguageDropdown right /> */}
      <div className="flex items-center justify-between py-6 px-5 space-x-2">
        {/* <ButtonPrimary href={"/"} className="!px-10">
          Buy this template
        </ButtonPrimary> */}
      </div>
    </div>
  )
}

export default NavMobile
