import backPng from "assets/images/home-page/image (1) (1).png"
import frontPng from "assets/images/home-page/front_3.png"
import front9partmodified from "assets/images/home-page/front_mobile_1.png"
import Image from "components/Image"
import {
  discord_48_Png,
  instagram_48_Png,
  tiktok_48_Png,
  youTube_48_Png,
} from "components/SocialMediaIcon/icons"
import useTailwindBreakpoint from "hooks/useTailwindBreakpoint"
import "./typewriter.css"
import { useTranslation } from "react-i18next"

function Section1() {
  const {
    screenSize: { width },
  } = useTailwindBreakpoint()
  const { t } = useTranslation()

  const desktop = () => {
    return (
      <div className="SectionHero2 relative pb-20 md:py-32 lg:py-60 bg-black lg:min-h-screen lg:flex lg:items-center lg:justify-center h-[80vh] md:h-auto lg:h-[70vh]">
        <div className="flex w-full mb-10 md:w-1/2 xl:w-2/5 md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
          <div className="hidden md:block absolute z-[1] top-0 left-0 bottom-0 w-44 from-black bg-gradient-to-r"></div>
          <Image fill className="object-cover" src={frontPng} alt="hero" />
        </div>

        <div className="container relative z-10 text-neutral-100">
          <h1 className="font-bold text-4xl md:text-5xl xl:text-6xl mt-3 md:!leading-[110%] typing-animation">
            Mladen Body Building
          </h1>

          <p className="mt-7 text-base lg:text-xl text-neutral-300 max-w-[50%]">
            {t("Home_About1")}
          </p>

          <p className="mt-7 text-base lg:text-xl text-neutral-300 max-w-[50%]">
            {t("Home_About2")}
          </p>

          <p className="mt-7 text-base lg:text-xl text-neutral-300 max-w-[50%]">
            {t("Home_About3")}
          </p>

          <div className="flex space-x-4 mt-11">
            <a
              href={"https://www.instagram.com/chetniksesports/"}
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <Image src={instagram_48_Png} />
            </a>

            <a
              href={"https://discord.com/invite/35euMVw"}
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <Image src={discord_48_Png} />
            </a>

            <a
              href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <Image src={youTube_48_Png} />
            </a>

            <a
              href={"https://www.tiktok.com/@chetniksesports"}
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <Image src={tiktok_48_Png} />
            </a>
          </div>
        </div>
      </div>
    )
  }

  const mobile = () => {
    return (
      <div className="SectionHero2 relative py-20 md:py-32 xl:py-60 bg-black md:min-h-screen md:flex md:items-center md:justify-center md:h-auto md:h-full">
        <div className="flex w-full md:w-1/2 xl:w-3/5 md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
          <Image
            fill
            className="xs:block sm:block md:block lg:hidden xl:hidden 2xl:hidden object-cover"
            src={backPng}
            alt="hero"
          />
        </div>

        <div className={`container relative z-10 text-neutral-100 `}>
          <h1
            className={`font-bold text-4xl md:text-5xl xl:text-6xl mt-3 md:!leading-[110%] text-center`}
          >
            Mladen Body Building
          </h1>

          <p className={`mt-7 text-base lg:text-xl text-neutral-150 text-center`}>
            {t("Home_About1")}
          </p>

          <div className="flex justify-center my-4">
            <Image className="" src={front9partmodified} alt="hero" width="200" />
          </div>

          <p className={`text-base lg:text-xl text-neutral-150 text-center`}>{t("Home_About2")}</p>

          <p className={`text-base lg:text-xl text-neutral-150 text-center mt-7`}>
            {t("Home_About3")}
          </p>

          <div className="flex justify-center space-x-4 mt-11">
            <a href={"https://www.instagram.com/chetniksesports/"} target="_blank" rel="noreferrer">
              <Image src={instagram_48_Png} />
            </a>

            <a href={"https://discord.com/invite/35euMVw"} target="_blank" rel="noreferrer">
              <Image src={discord_48_Png} />
            </a>

            <a
              href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={youTube_48_Png} />
            </a>

            <a href={"https://www.tiktok.com/@chetniksesports"} target="_blank" rel="noreferrer">
              <Image src={tiktok_48_Png} />
            </a>
          </div>
        </div>
      </div>
    )
  }

  return width === "xs" || width === "sm" ? mobile() : desktop()
}

export default Section1
