import { Popover, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import Flag from "react-world-flags"

function LanguageDropdown({ right = false }) {
  const { t, i18n } = useTranslation()

  const getClassName = lang => {
    return `${
      i18n.language === lang ? "bg-neutral-500" : ""
    } cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`
  }

  return (
    <div className="LanguageDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
            >
              <svg
                className=" w-6 h-6 opacity-80"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                ></path>
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-10 px-4 mt-3.5 ${
                  right ? "left-0" : "right-0 w-screen max-w-[260px]"
                } sm:px-0`}
              >
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                    <div
                      className={getClassName("srC")}
                      disabled={i18n.LANGU === "srC"}
                      onClick={() => {
                        i18n.changeLanguage("srC")
                      }}
                    >
                      <div className="">
                        <Flag code={"RS"} style={{ width: 20 }} />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">{t("Language_Serbian_Cyrillic")}</p>
                      </div>
                    </div>

                    <div
                      className={getClassName("srL")}
                      disabled={i18n.language === "srL"}
                      onClick={() => {
                        i18n.changeLanguage("srL")
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <Flag code={"RS"} style={{ width: 20 }} />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">{t("Language_Serbian_Latin")}</p>
                      </div>
                    </div>

                    <div
                      className={getClassName("en")}
                      disabled={i18n.language === "en"}
                      onClick={() => {
                        i18n.changeLanguage("en")
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <Flag code={"GB"} style={{ width: 20 }} />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">{t("Language_English")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default LanguageDropdown
